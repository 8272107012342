<template>
  <div>
    <h1 style="margin:0 auto">欢迎使用数智化系统！</h1>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
